.App {
  text-align: center;
  height: 100%;
  position: relative;
  font-family: 'Raleway', sans-serif;

}




.main {
  margin-top: 70px;
  height:100%;
}






.footer {
  padding-top: 300px;
  font-size: 20px;
 
}



