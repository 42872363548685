.navbar {
    position: fixed;
    top: 0;
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
    overflow: hidden;
    background-color: white;
    z-index: 1;
    
    
}

.DarkMode .navbar {
    background-color: black;
}


.navbar > ul{
    list-style:none;
    display:flex;
    margin-left:auto;
    margin-right:auto;
    padding: 10px; 
    margin: 0;
   
   

}

.navbar > ul > li{
    margin-left:auto;
    margin-right:auto;
    margin-top: auto;
   
    
}
.navbar > ul > li > a{
    text-decoration:none;
    font-weight: bold;
}

.navbar > ul > li > a:hover{
    background-color:white;
    color: crimson;
}

.DarkMode .navbar > ul > li > a:hover{
    background-color:black;
   
}

a {
    color: black;
    font-weight: bold;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    content: "hi";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: pink;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "🔆";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

 
  
  input:checked + .slider {
    background-color: crimson;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    content:"🌙";
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  
  
  
  
  