.sidebar{
    width: 60px; /* Set the width of the sidebar */
    height: 100%;
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    /* top: 0; Stay at the top */
    /* right: 0; */
    top: 47%;
    left: 95%;
    transform: translate(-50%, -50%);
    overflow-x: hidden; /* Disable horizontal scroll */
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  /* The navigation menu links */
  .sidebar a {
    padding: 16px 8px 6px ;
    display: block;
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidebar a:hover {
    color: crimson;
  }
  
  

  
  