.portfolio {
    padding-top: 100px;
    width: 100%;
}



.portfolio > ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-right: 40px;
    margin-top: 50px;
    
  
    

}

.portfolio > ul > li {
    flex-direction: row;
    padding: 30px;

}


.modal {
    font-size: 12px;
    background-color: pink;
    height: 90%;
    left: 30px;
    position:fixed;
    top: 50px;
    width: 94%;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Raleway', sans-serif;
    border-style: solid;
    border-color: rgb(204, 34, 119);
    border-width: thin;
    border-radius: 10px; 

    

}

.DarkMode .modal {
    background-color: black;
    border-color: white;
}


.modal-header {
    width: 100%;
    font-size: x-large;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 5px;

}


.content {
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    display: flex;
    font-size: large;
    
   
}

.modal-text {
    margin-right: 40px;
    margin-top: 8px;
    margin-left: 15px;
   
   
    
}

.modal-images {
    margin: 20px;
 
}

.m-img {
    padding-top: 10px;
}






.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -8px;
    top: -7px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
    margin: 10px;

}


.heroku {
    margin-bottom: -6px;
    padding-left: 10px;
    

}

.project-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: transform .2s;
    border-radius: 10px; 
}

.DarkMode .project-card {
    border-style: solid;
    border-color: white;
    border-width: thin;
   
}

.project-card:hover {
    transform: scale(1.1);
}

.project-card > h3 {
padding-top: 10px;}

.view-button {
    margin-bottom: 10px;
    font-family: 'Raleway', sans-serif;
    background-color: white;
    border: 1px, solid, black;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 10px;
    
}

.view-button:hover {
    cursor: pointer;
}