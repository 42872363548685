.about {
    padding-top: 100px;
    margin-top: 300px;
    margin-bottom: 300px;
    font-size: 20px;
  }
  
.about-content {
    width: 90%;
    display: flex;
    margin-left: 30px;
    
}